<template>
  <div id="region" class="sidecard">
    <p class="label">Current region:</p>
    <p class="curReg">{{ longRegion }}</p>
    <button @click="changeRegion('br1')">BR</button>
    <button @click="changeRegion('eun1')">EUNE</button>
    <button @click="changeRegion('euw1')">EUW</button>
    <button @click="changeRegion('jp1')">JP</button>
    <button @click="changeRegion('kr')">KR</button>
    <button @click="changeRegion('la1')">LAN</button>
    <button @click="changeRegion('la2')">LAS</button>
    <button @click="changeRegion('na1')">NA</button>
    <button @click="changeRegion('oc1')">OCE</button>
    <button @click="changeRegion('ru')">RU</button>
    <button @click="changeRegion('tr1')">TR</button>
  </div>
</template>

<script>

const regionNames = {
    br1: "Brazil",
    eun1: "Europe Nordic & E.",
    euw1: "Europe W.",
    jp1: "Japan",
    kr: "Korea",
    la1: "Latin America N.",
    la2: "Latin America S.",
    na1: "N. America",
    oc1: "Oceania",
    ru: "Russia",
    tr1: "Turkey",
}

export default {
  props: ["region"],
  /* refactor below */
  methods: {
    changeRegion(region) {
        this.$emit("changeRegion", region)
    },
  },
  computed: {
    longRegion() {
        return regionNames[this.region]
    },
  },
};
</script>

<style>
</style>