<template>
  <div id="nowShowing" class="sidecard">
    <p>Current date:</p>
    <p v-if="presDate">{{ medDate }}</p>
    <span>
      <button @click="left" :disabled="presDate === min_date">&#8592;</button>
      <button @click="right" :disabled="presDate === max_date">&#8594;</button>
    </span>
  </div>
</template>

<script>
export default {
  props: ["min_date", "max_date", "presDate"],
  methods: {
    left() {
      this.$emit("left");
    },
    right() {
      this.$emit("right");
    },
  },
  computed: {
    medDate() {
      const dateObj = new Date(this.presDate + "T00:00:00");
      const options = {
        month: "short",
        day: "numeric",
        year: "numeric",
      };
      return dateObj.toLocaleString("default", options);
    },
  },
};
</script>