<template>
  <header class="content">
    <h1>CHALLENGERS</h1>
    <p v-if="presDate">of {{ longDate }}</p>
  </header>
  <section id="main" class="content">
    <div v-if="initiated">
      <div v-if="presRows">
        <table>
          <thead>
            <tr>
              <th id="rnk">Rank</th>
              <th id="summName">Summoner Name</th>
              <th id="lp">League Points</th>
            </tr>
          </thead>
          <tbody v-if="presRows.length">
            <tr v-for="row in presRows" :key="row.rank">
              <td>
                {{ row.rank }}
              </td>
              <td>
                {{ row.summonerName }}
              </td>
              <td>
                {{ row.leaguePoints }}
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td>...</td>
              <td>...</td>
              <td>...</td>
            </tr>
          </tbody>
        </table>
        <p id="emptyNotice" v-if="!presRows.length">
          There were no challengers in this region on this date.
        </p>
      </div>
      <countdown v-else :region="region" :initDate="initDate" />
    </div>
  </section>
</template>

<script>
// @ is an alias to /src

import Countdown from "@/components/Countdown.vue";

export default {
  name: "Home",
  components: {
    Countdown,
  },
  props: ["presRows", "presDate", "region", "initDate", "initiated"],
  computed: {
    longDate() {
      const dateObj = new Date(this.presDate + "T00:00:00");
      const options = {
        month: "long",
        day: "numeric",
        year: "numeric",
      };
      return dateObj.toLocaleString("default", options);
    },
  },
};
</script>