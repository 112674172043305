<template>
  <div class="noDate">
    <p> No data found for this date and region. </p>
    <p> The roster for {{ longRegion }} will be updated at</p>
    <p>{{ utcString }} UTC+0 </p>
    <p> which is </p>
    <p> 11:50 P.M. UTC{{ offsetString }}</p>
    <p> which is in</p>
    <p> {{ hoursLeft }} hours, {{ minutesLeft }} minutes, and
      {{ secondsLeft }} seconds. </p>
  </div>
</template>

<script>

const regionNames = {
    br1: "Brazil",
    eun1: "Europe Nordic and East",
    euw1: "Europe West",
    jp1: "Japan",
    kr: "Korea",
    la1: "Latin America North",
    la2: "Latin America South",
    na1: "North America",
    oc1: "Oceania",
    ru: "Russia",
    tr1: "Turkey",
}

const OFFSET = {
    br1: -3,
    eun1: 1,
    euw1: 0,
    jp1: 9,
    kr: 9,
    la1: -6,
    la2: -3,
    na1: -8,
    oc1: 11,
    ru: 3,
    tr1: 3,
}

const UTCSTRING = {
    br1: "2:50 A.M.",
    eun1: "10:50 P.M.",
    euw1: "11:50 A.M.",
    jp1: "2:50 P.M.",
    kr: "2:50 P.M.",
    la1: "5:50 A.M.",
    la2: "2:50 A.M.",
    na1: "7:50 A.M.",
    oc1: "12:50 P.M.",
    ru: "8:50 P.M.",
    tr1: "8:50 P.M.",
}

export default {
  name: 'Countdown',
  props: ['region', 'initDate'],
  data() {
      return {
          time: 0,
      }
  },
  methods: {
      setTimer() {
          const values = this.initDate.split('-')
          const d = new Date(Date.UTC(values[0], values[1] - 1, values[2], 23, 50))
          const targetTime = d.getTime() - 3600000 * OFFSET[this.region]
          this.time = parseInt((targetTime - Date.now())/1000)
      }
  },
  watch: {
      region(newVal, oldVal) {
        this.setTimer()    
      }
  },
  computed: {
    longRegion() {
      return regionNames[this.region]
    },
    hoursLeft() {
      return parseInt(this.time/3600)
    },
    minutesLeft() {
      return parseInt( (this.time % 3600) / 60)
    },
    secondsLeft() {
      return parseInt(this.time % 60)
    },
    offsetString() {
        const offset = OFFSET[this.region]
        if (offset < 0) {
            return String(offset)
        } else {
            return "+" + String(offset)
        }
    },
    utcString() {
      return UTCSTRING[this.region]
    }
  },
  beforeMount() {
      this.setTimer()
      this.interval = setInterval(() => {
          this.time -= 1
      }, 1000)
  },
}
</script>

<style>

</style>