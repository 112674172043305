<template>
  <div class="side">
    <now-showing
      :min_date="min_date"
      :max_date="max_date"
      :presDate="presDate"
      @left="subtractDay"
      @right="addDay"
    />
    <nav id="siteNav" class="sidecard">
      <router-link to="/">Data</router-link>
      <router-link to="/about">About</router-link>
    </nav>
    <region-control @changeRegion="updateRegion" :region="region" />
  </div>
  <article>
    <router-view
      :presRows="presRows"
      :presDate="presDate"
      :region="region"
      :initDate="initDate"
      :initiated="initiated"
    />
  </article>
  <div class="side">
    <date-control :min_date="min_date" :max_date="max_date" @go="matchPicker" />
    <nav id="twitters" class="sidecard">
      <p>Twitter Bots</p>
      <a href="https://twitter.com/NAChallengers">Unique Promotions</a>
      <a href="https://twitter.com/NAChallengersPD">Promos/Demos</a>
      <a href="https://twitter.com/NAChallengersNS">Nameswaps</a>
      <a href="https://twitter.com/loldrbrd">Site Ambassador</a>
    </nav>
  </div>
</template>

<script>
const site = "https://hallofchallengers.com/";

const REGIONS = [
  "br1",
  "eun1",
  "euw1",
  "jp1",
  "kr",
  "la1",
  "la2",
  "na1",
  "oc1",
  "ru",
  "tr1",
];

function dayDelta(dt, d) {
  const things = dt.split("-");
  const newDate = new Date(
    Date.UTC(
      parseInt(things[0]),
      parseInt(things[1] - 1),
      parseInt(things[2])
    ) +
      d * 24 * 3600000
  );
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    timeZone: "UTC",
  };
  return newDate
    .toLocaleString("en-GB", options)
    .split("/")
    .reverse()
    .join("-");
}

function getSlashes(dt) {
  return dt.split("-").join("/");
}

import DateControl from "@/components/DateControl.vue";
import NowShowing from "@/components/NowShowing.vue";
import RegionControl from "./components/RegionControl.vue";

export default {
  components: {
    DateControl,
    NowShowing,
    RegionControl,
  },
  data() {
    return {
      min_date: "",
      max_date: "",
      presDate: "",
      initDate: "",
      ystrRows: false,
      presRows: false,
      tomrRows: false,
      region: "na1",
      initiated: false,
    };
  },
  methods: {
    subtractDay() {
      this.presDate = dayDelta(this.presDate, -1);
      this.tomrRows = this.presRows;
      this.presRows = this.ystrRows;
      this.getYstr();
    },
    addDay() {
      this.presDate = dayDelta(this.presDate, 1);
      this.ystrRows = this.presRows;
      this.presRows = this.tomrRows;
      this.getTomr();
    },
    getYstr() {
      fetch(site + this.ystrDate + "/" + this.region + ".json").then(
        async (resp) => {
          if (resp.ok) {
            const data = await resp.json();
            this.ystrRows = data;
          } else {
            this.ystrRows = false;
          }
        }
      );
    },
    getPres() {
      fetch(
        site + getSlashes(this.presDate) + "/" + this.region + ".json"
      ).then(async (resp) => {
        if (resp.ok) {
          const data = await resp.json();
          this.presRows = data;
          this.initiated = true;
        } else {
          this.presRows = false;
          this.initiated = true;
        }
      });
    },
    getTomr() {
      fetch(site + this.tomrDate + "/" + this.region + ".json").then(
        async (resp) => {
          if (resp.ok) {
            const data = await resp.json();
            this.tomrRows = data;
          } else {
            this.tomrRows = false;
          }
        }
      );
    },
    getRows() {
      this.getPres();
      this.getYstr();
      this.getTomr();
    },
    matchPicker(pickerDate) {
      if (pickerDate) {
        this.initiated = false;
        this.presDate = pickerDate;
        this.getRows();
      }
    },
    updateRegion(region) {
      this.initiated = false;
      this.region = region;
      this.getRows();
    },
  },
  computed: {
    ystrDate() {
      return getSlashes(dayDelta(this.presDate, -1));
    },
    tomrDate() {
      return getSlashes(dayDelta(this.presDate, 1));
    },
  },
  beforeMount() {
    fetch(site + "params.json")
      .then((resp) => resp.json())
      .then((data) => {
        this.min_date = data.min;
        this.max_date = data.max;
        this.presDate = data.init;
        this.initDate = data.init;
        this.getRows();
      });
  },
};
</script>


<style>
@import "./assets/css/global.css";
</style>
