<template>
<div id="dateController" class="sidecard">
  <label for="jerry">Choose a date:</label><br>
  <input type="date" id="jerry" :min="min_date" :max="max_date" v-model="pickerDate"><br>
  <button @click="go">Go</button>
</div>
</template>

<script>
export default {
  props: ['min_date', 'max_date'],
  data() {
    return {
      pickerDate: "" 
    }
  },
  methods: {
    back() {
      this.$emit('left')
    },
    forward() {
      this.$emit('right')
    },
    go() {
      this.$emit('go', this.pickerDate)
    }
  }
}

</script>

<style scoped>
button {
  margin: 5px
}
</style>